import React from 'react'
import { ThemeProvider } from '@mui/material'
import { PageProps, graphql } from 'gatsby'
import Layout from '@talentinc/gatsby-theme-ecom/components/Layout'
import ReviewPage from '@talentinc/gatsby-theme-ecom/components/Reviews/ReviewPage'
import SEO from '@talentinc/gatsby-theme-ecom/components/SEO'
import { PageDataContextType } from '@talentinc/gatsby-theme-ecom/types/page'
import { ReviewPage as ContentfulReviewPage } from '@talentinc/gatsby-theme-ecom/types/review'
import Footer from '../components/Footer'
import HeaderV2 from '../components/HeaderV2'
import { ZipJobLandingTheme } from '../styles'

const ReviewTemplate: React.FC<
  PageProps<PageDataContextType & { reviewPage: ContentfulReviewPage }>
> = (props) => {
  const { reviewPage, ...pageData } = props.data

  return (
    <ThemeProvider theme={ZipJobLandingTheme}>
      <Layout pageData={pageData}>
        <SEO landingPage={reviewPage} />
        <HeaderV2 />
        <ReviewPage reviewPage={reviewPage} />
        <Footer />
      </Layout>
    </ThemeProvider>
  )
}

export const query = graphql`
  query ReviewPageBySlugAndBrand($slug: String!, $brandName: String!) {
    reviewPage: contentfulReviewPage(
      slug: { eq: $slug }
      brand: { elemMatch: { name: { eq: $brandName } } }
    ) {
      ...ReviewPage
    }
    brand: contentfulBrand(name: { eq: $brandName }) {
      ...Brand
    }
    headerV2: contentfulHeaderV2(
      brand: { elemMatch: { name: { eq: $brandName } } }
    ) {
      ...HeaderV2
    }
    footer: contentfulFooter(brand: { name: { eq: $brandName } }) {
      ...Footer
    }
    locales: allLocale(filter: { language: { eq: "en" } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default ReviewTemplate
